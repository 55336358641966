'use strict'
const _ = require('lodash')
const {VARIANTS_CSS_STRATEGIES, Z_LAYER_VALUE_MAP, REF_DELIMITER} = require('../../utils/constants')
const {constants} = require('santa-core-utils')
const {displayedOnlyStructureUtil} = require('santa-core-utils')

const VARIANT_TYPES = {
    MOBILE: 'Mobile',
    HOVER: 'Hover',
    CLASS: 'Class',
    PRESET: 'Preset'
}
const OUTER_VARIANTS = [VARIANT_TYPES.MOBILE, VARIANT_TYPES.PRESET]

const transformKeys = ['scale', 'rotate', 'translate', 'skew']
const DEFAULT_TRANSLATE = 'translateX(0)translateY(0) '
const DEFAULT_SCALE = 'scaleX(1)scaleY(1) '
const DEFAULT_SKEW = 'skewX(0deg)skewY(0deg) '
const DEFAULT_ROTATE = 'rotate(0deg) '

const isPropertyDefined = (property, styleItem) => styleItem.hasOwnProperty(property) && styleItem[property] !== undefined && styleItem[property] !== null

const omitUndefinedProperties = styleItem => _.pickBy(styleItem, (__, property) => isPropertyDefined(property, styleItem))
const omitIgnoredProperties = (styleItem, ignoredProperties) => _.pickBy(styleItem, (__, property) => !ignoredProperties.includes(property))

const getSelectorByVariant = (variant, isMobileView) => {
    switch (variant.type) {
        case VARIANT_TYPES.HOVER:
            return isMobileView ? `.${variant.id}` : ':hover'
        case VARIANT_TYPES.MOBILE:
            return '.device-mobile-optimized'
        case VARIANT_TYPES.CLASS:
        case VARIANT_TYPES.PRESET:
            return `.${variant.id}`
        default:
            // no matching type, log it
            console.error(`Variant with type ${variant.type} does not exist.`)
            return ''
    }
}
const getAdditionalPrefixes = (prefixes, suffix) => {
    if (!prefixes || !prefixes.length) {
        return ''
    }
    return prefixes.map(prefix => `,${prefix}${suffix}`)
}

const getUnit = unit => {
    if (unit === 'percentage') {
        return '%'
    }
    return unit
}

const resolveValueWithUnit = valueWithUnit => valueWithUnit ? `${valueWithUnit.value}${getUnit(valueWithUnit.type)}` : 0

const resolveValue = value => value || 0

const isStyleItemContainsTransformProps = styleItem => transformKeys.some(property => isPropertyDefined(property, styleItem))

const applyCSSToChildren = (rule, strategy) =>
    strategy === VARIANTS_CSS_STRATEGIES.ALL_CHILDREN ? `${rule}, ${rule} *` : `${rule}, ${rule} > *`

const getTranslateString = translate => {
    let translateString = ''
    if (!translate) {
        return DEFAULT_TRANSLATE
    }
    const {x: translateX, y: translateY} = translate
    if (translateX) {
        translateString += `translateX(${resolveValueWithUnit(translateX)}) `
    } else {
        translateString += 'translateX(0) '
    }
    if (translateY) {
        translateString += `translateY(${resolveValueWithUnit(translateY)}) `
    } else {
        translateString += 'translateY(0) '
    }
    return translateString
}

const getScaleString = scale => {
    let scaleString = ''
    if (!scale) {
        return DEFAULT_SCALE
    }
    const {x: scaleX = 1, y: scaleY = 1} = scale
    if (_.isNumber(scaleX)) {
        scaleString += `scaleX(${scaleX}) `
    }
    if (_.isNumber(scaleY)) {
        scaleString += `scaleY(${scaleY}) `
    }
    return scaleString
}

const getRotateString = (rotate, componentDefaultRotation) => {
    if (_.isNumber(rotate) || componentDefaultRotation) {
        const compRotation = componentDefaultRotation || 0
        const variantRotation = rotate || 0
        return `rotate(${resolveValue(variantRotation) + compRotation}deg) `
    }
    return DEFAULT_ROTATE
}

const getSkewString = skew => {
    let skewString = ''
    if (!skew) {
        return DEFAULT_SKEW
    }
    const {x: skewX = 0, y: skewY = 0} = skew
    if (_.isNumber(skewX)) {
        skewString += `skewX(${skewX}deg) `
    }
    if (_.isNumber(skewY)) {
        skewString += `skewY(${skewY}deg) `
    }
    return skewString
}

const getOriginalAncestorsRefIds = id => id.split(REF_DELIMITER)
const getOriginalId = id => _.last(getOriginalAncestorsRefIds(id))

const getRefId = id => _.head(id.split(`${REF_DELIMITER}${getOriginalId(id)}`))
const getUniqueId = (refId, originalId) => `${refId}${REF_DELIMITER}${originalId}`

const getInflatedRepeaterId = (compIdToFix, containerId) => {
    const itemId = displayedOnlyStructureUtil.getRepeaterItemId(containerId)
    if (itemId) {
        compIdToFix = displayedOnlyStructureUtil.getUniqueDisplayedId(compIdToFix, itemId)
    }
    return compIdToFix
}
const getRefInflatedId = (compIdToFix, containerId) => {
    const refId = getRefId(containerId)
    if (refId !== containerId) {
        return getUniqueId(refId, compIdToFix)
    }
    return compIdToFix
}

const fixDisplayedOnlyCompId = (compIdToFix, containerId) => {
    const repeaterInflatedId = getInflatedRepeaterId(compIdToFix, containerId)
    return getRefInflatedId(repeaterInflatedId, containerId)
}

const addA11yRules = rule => {
    if (_.includes(rule, ':hover')) {
        const focusRule = rule.replace(':hover', ':focus-within')
        return `${rule},.keyboard-tabbing-on ${focusRule}`
    }
    return rule
}

const splitVariantsByType = variants => {
    const outerVariantsTypes = OUTER_VARIANTS
    const arrs = _.partition(variants, variant =>
        !_.includes(outerVariantsTypes, variant.type))
    return {innerVariants: arrs[0], outerVariants: arrs[1]}
}

const getOuterVariantsPrefix = (variants, isMobileView) => {
    const selectors = variants.reduce((acc, variant) => {
        acc += getSelectorByVariant(variant, isMobileView)
        return acc
    }, '')
    return variants.length ? `${selectors} ` : ''
}
const combineCssRules = (baseRule, ruleSuffix) =>
    baseRule.split(',').map(rulePart => `${rulePart}${ruleSuffix}`).join()

const getTransformationOverrides = transformationData => {
    if (transformationData && isStyleItemContainsTransformProps(transformationData)) {
        const {TRANSFORMATIONS_ORDER} = constants
        const {scale, rotate, translate, skew} = transformationData

        const transformationsString = {
            translate: getTranslateString(translate),
            scale: getScaleString(scale),
            rotate: getRotateString(rotate),
            skew: getSkewString(skew)
        }

        const transformStringValue = TRANSFORMATIONS_ORDER.reduce((transformString, transformKey) =>
            `${transformString}${transformationsString[transformKey]}`, '').trim()
        if (transformStringValue) {
            return {
                transform: transformStringValue
            }
        }
    }
    return {}
}


module.exports = {
    functionLibrary: {
        getTransformationOverrides,
        getCSSRules: (cssString, selectors, isComponentStyle, defaultPrefixes) => isComponentStyle ?
            // eslint-disable-next-line no-control-regex
            cssString.replace(new RegExp('([^\r\n,{}]+)(,(?=[^}]*{)|\s*{)', 'g'), rule => {
                let cssRule = rule.trim()
                const lastChar = cssRule.charAt(cssRule.length - 1)
                cssRule = lastChar === '{' || lastChar === ',' ? cssRule.slice(0, -1) : cssRule
                const res = `${combineCssRules(selectors, cssRule)}, ${combineCssRules(selectors, ` ${cssRule}`)}${getAdditionalPrefixes(defaultPrefixes, `${cssRule}`)}${getAdditionalPrefixes(defaultPrefixes, ` ${cssRule}`)}`
                return `${res}${lastChar}`
            }) : `${selectors}${getAdditionalPrefixes(defaultPrefixes, '')} ${cssString}`,

        getCSSFromTransformationItem: (styleItem, componentDefaultRotation, ignoredTransformProps) => {
            const css = []
            const filteredStyleItem = omitIgnoredProperties(omitUndefinedProperties(styleItem), ignoredTransformProps)
            if (Z_LAYER_VALUE_MAP[filteredStyleItem.zLayer]) {
                css.push(`z-index: ${Z_LAYER_VALUE_MAP[filteredStyleItem.zLayer]} `)
            }

            if (filteredStyleItem.hasOwnProperty('hidden')) {
                css.push(`opacity: ${filteredStyleItem.hidden ? 0 : 1} `)
                css.push(`visibility: ${filteredStyleItem.hidden ? 'hidden' : 'visible'} `)
            } else if (filteredStyleItem.hasOwnProperty('opacity')) {
                css.push(`opacity: ${filteredStyleItem.opacity} `)
            }

            if (filteredStyleItem.origin) {
                css.push(`transform-origin: ${resolveValueWithUnit(filteredStyleItem.origin.x)} ${resolveValueWithUnit(filteredStyleItem.origin.y)} `)
            }
            if (filteredStyleItem.filter) {
                const {blur = 0, opacity = 100, grayscale = 0} = filteredStyleItem.filter
                css.push(`filter: opacity(${opacity}%) grayscale(${grayscale}%) blur(${blur}px) `)
            }
            if (isStyleItemContainsTransformProps(filteredStyleItem)) {
                const {TRANSFORMATIONS_ORDER} = constants
                const {scale, rotate, translate, skew} = filteredStyleItem

                const transformationsString = {
                    translate: getTranslateString(translate),
                    scale: getScaleString(scale),
                    rotate: getRotateString(rotate, componentDefaultRotation),
                    skew: getSkewString(skew)
                }

                const transformStringValue = TRANSFORMATIONS_ORDER.reduce((transformString, transformKey) =>
                    `${transformString}${transformationsString[transformKey]}`, '').trim()
                if (transformStringValue) {
                    const transformString = `transform: ${transformStringValue}`
                    css.push(transformString)
                }
            }

            return css.length ? `{ ${css.join('; ')}}` : '{}'
        },
        getCSSFromTransitionItem: styleItem => {
            const {property = 'all', 'timing-function': timingFunction = 'ease', duration = 0, delay = 0} = styleItem
            return `{ transition: ${property} ${duration}s ${timingFunction} ${delay}s }`
        },
        getSelectorsForStyleChanges: (variants, compId, isRepeaterChild, isMobileView) => {
            let variantCompId = variants[0].componentId
            let variantType = variants[0].type
            const refId = getRefId(compId)
            // for inner variants, if the style refArray is on a ref comp, inflate the compId to which the variant condition points to as well.
            // outer variants are not pointing to element in the ref so they can be excluded.
            // TODO for class variants that are targeted outside the refComp we may still have an issue.
            const getRefInflatedCompId = (variantComponentId, type) => refId !== compId && !_.includes(OUTER_VARIANTS, type) ?
                getUniqueId(refId, variantComponentId) : variantComponentId
            let selectors = isRepeaterChild ? `[id^="${getRefInflatedCompId(variantCompId, variantType)}"]` : `#${getRefInflatedCompId(variantCompId, variantType)}`
            let currentsSource = variantCompId

            variants.forEach(variant => {
                variantCompId = variant.componentId
                variantType = variants.type
                const selector = getSelectorByVariant(variant, isMobileView)
                const refInflatedCompId = getRefInflatedCompId(variantCompId, variantType)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` [id^="${refInflatedCompId}"]`
                    } else {
                        selectors += ` #${refInflatedCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` [id^="${compId}"]`
            }
            selectors = addA11yRules(selectors)
            return selectors
        },
        getSelectorsForEffects: (variants, compId, applyToChildrenStrategy, isMobileView) => {
            const {innerVariants, outerVariants} = splitVariantsByType(variants)
            const outerVariantsPrefix = getOuterVariantsPrefix(outerVariants, isMobileView)

            let currentsSource = innerVariants.length ?
                fixDisplayedOnlyCompId(innerVariants[0].componentId, compId) :
                `${compId}`
            let selectors = `${outerVariantsPrefix}#${currentsSource}`
            innerVariants.forEach(variant => {
                const variantCompId = fixDisplayedOnlyCompId(variant.componentId, compId)
                const selector = getSelectorByVariant(variant, isMobileView)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` #${variantCompId}`
                    } else {
                        selectors += ` #${variantCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` #${compId}`
            }
            selectors = addA11yRules(selectors)
            selectors = applyToChildrenStrategy ? applyCSSToChildren(selectors, applyToChildrenStrategy) : selectors
            return `${selectors}`
        },
        applyRuleToChildren: (cssRule, applyToChildrenStrategy) => cssRule.split(',')
            .map(s => s.trim())
            .map(css => applyCSSToChildren(css, applyToChildrenStrategy))
            .join(),
        getRefInflatedId,
        getCompIdToVariantId: variant => {
            const {componentId, id} = variant
            if (componentId) {
                return {[componentId]: id}
            }
            return {}
        }
    }
}
